export const SMALL = `
@media screen and (min-width: 20rem)
`;

export const MEDIUM = `
@media screen and (min-width: 48rem)	
`;

export const LARGE = `
@media screen and (min-width: 64rem)`;

export const XLARGE = `
@media screen and (min-width: 80rem)
`;

export const XXLARGE = `
@media (min-width: 94rem)

`;
